"use strict";
window.CI = window.CI || {};

(function ($$) {

  // 基本設定 START ▼▼▼▼▼▼▼▼▼▼
  $$.fn = {};

  $$.settings = {
    tmpScrollTop: null,
    breakpoint1: 576,
    breakpoint2: 992,
    startTime: null,
  };
  $$.settings.mql = window.matchMedia(`(min-width: ${$$.settings.breakpoint1 + 1}px) and (max-width: ${$$.settings.breakpoint2}px)`);

  $$.ua = {
    root: navigator.userAgent,
    isIE: (navigator.userAgent.indexOf('Trident') !== -1) ? true : false,
    isEDGE: (navigator.userAgent.indexOf('Edg') !== -1) ? true : false,
  };

  $$.ease = {
    linear: x => x,
    out: {
      cubic: (x) => {
        return 1 - Math.pow(1 - x, 3);
      },
    },
  };

  $$.elem = {
    body: document.querySelector('body'),
  };

  $$.fn.debug = (title, cb) => {
    if (location.search === '') return;
    const match = location.search.match(/(\?|\$)debug=(\d)/) || false;
    if (match[2] === "1") {
      console.log(`::::: ${title} ::::: ${new Date()}`);
      cb();
    }
  }

  /**
   *
   * @param {function} doSomething アニメーションの挙動を記述する。引数eに情報が入っている
   * @param {number} duration アニメーション時間をmsで設定
   * @param {function} easing イージング関数を指定する。デフォルトを指定したい場合はundefinedにする
   * @param {function} callback アニメーション完了後に実行したい挙動を記述する。
   */
  $$.fn.animate = (doSomething, duration = 1000, easing = $$.ease.out.cubic, callback = null) => {
    const e = {};
    $$.settings.startTime = $$.settings.startTime || Date.now();

    const run = () => {
      let nowTime = Date.now();
      let elapsed = (duration - (nowTime - $$.settings.startTime) > 0) ? (nowTime - $$.settings.startTime) : duration;
      e.progress = elapsed / duration;
      e.easing = easing;

      doSomething(e);

      if (elapsed < duration) {
        requestAnimationFrame(run);
      } else {
        $$.settings.startTime = null;
        if (callback) callback();
      }
    }
    requestAnimationFrame(run);
  }

  /**
   *
   * @param {element}} el 値を取得したい要素
   * @param {string} pos 値を取得する位置(top/left/right/bottom)
   * @returns
   */
  $$.fn.offset = (el, pos) => {
    let result = 0;
    let rect = el.getBoundingClientRect();

    switch (pos) {
      case 'top':
        result = rect.top + window.scrollY;
        break;
      case 'left':
        result = rect.left + window.scrollX;
        break;
      case 'right':
        result = rect.left + window.scrollX + el.clientWidth;
        break;
      case 'bottom':
        result = rect.top + window.scrollY + el.clientHeight;
        break;
      default:
        break;
    }

    return result;
  };

  /**
   *
   * @param {number} target スクロールしたい位置
   * @param {number} time アニメーション時間(ms)
   * @param {object} easing イージング設定(デフォルト：$$.ease.out.cubic)
   */
  $$.fn.smoothScroll = (target, time = 1000, easing = $$.ease.out.cubic) => {
    let startPos = document.documentElement.scrollTop || document.body.scrollTop;
    let distance = target - startPos;
    let position = 0;

    $$.fn.animate((e) => {
      position = startPos + (distance * e.easing(e.progress));
      window.scrollTo(0, position);
    }, time, easing);

    $$.fn.debug('smoothScroll', () => {
      console.log(`target:: ${target}`);
    });
  };

  /**
   *
   * @param {element} elem 高さを取得したい要素
   * @returns 高さの数値
   */
  $$.fn.getHeight = (elem) => {
    let clone = elem.cloneNode(true);
    elem.parentNode.appendChild(clone);
    clone.style.cssText = "display:block; height:auto; visibility:hidden;";
    let cloneHeight = clone.offsetHeight;
    elem.parentNode.removeChild(clone);

    $$.fn.debug('getHeight', () => {
      elem.dataset.height = cloneHeight;
    });

    return cloneHeight;
  };

  /**
   *
   * @param {element} elem スライドダウンしたい要素
   * @param {number} duration アニメーション時間(ms)
   * @param {object} easing イージング設定値(デフォルト：$$.ease.out.cubic)
   * @param {*} callback アニメーション完了後に走らせたい処理
   */
  $$.fn.slideDown = (elem, duration = 400, easing = $$.ease.out.cubic, callback = null) => {
    const height = $$.fn.getHeight(elem);
    $$.fn.animate((e) => {
      elem.style.display = 'block';
      elem.style.overflow = 'hidden';
      elem.style.height = `${height * e.easing(e.progress)}px`;
    }, duration, easing, () => {
      elem.style.overflow = '';
      if (callback) callback();
    });
  };

  /**
   *
   * @param {element} elem スライドアップしたい要素
   * @param {number} duration アニメーション時間(ms)
   * @param {object} easing イージング設定値(デフォルト：$$.ease.out.cubic)
   * @param {*} callback アニメーション完了後に走らせたい処理
   */
  $$.fn.slideUp = (elem, duration = 400, easing = $$.ease.out.cubic, callback = null) => {
    const height = $$.fn.getHeight(elem);
    $$.fn.animate((e) => {
      elem.style.height = `${height - (height * e.easing(e.progress))}px`;
      elem.style.overflow = 'hidden';
    }, duration, easing, () => {
      elem.style = '';
      if (callback) callback();
    });
  };

  /**
   *
   * @param {string} type 判定したいデバイス値(PC/TB/SP)
   * @returns 判定結果が帰る。typeが空の場合は現在のデバイス値が何かコンソールで確認できる。
   */
  $$.fn.isDevices = (type = null) => {
    let flag;
    switch (type) {
      case 'PC':
        flag = window.matchMedia(`(min-width: ${$$.settings.breakpoint2 + 1}px)`).matches;
        $$.fn.debug('isDevices', () => {
          console.log(`(min-width: ${$$.settings.breakpoint2 + 1}px)`);
          console.log(`isPC:: ${flag}`);
        });
        return flag;
        break;
      case 'TB':
        flag = !window.matchMedia(`(min-width: ${$$.settings.breakpoint2 + 1}px)`).matches && !window.matchMedia(`(max-width: {$$.settings.breakpoint1}px)`).matches;
        $$.fn.debug('isDevices', () => {
          console.log(`(min-width: ${$$.settings.breakpoint2 + 1}px)`).matches && !window.matchMedia(`(max-width: {$$.settings.breakpoint1}px)`);
          console.log(`isTB:: ${flag}`);
        });
        return flag;
        break;
      case 'SP':
        flag = window.matchMedia(`(max-width: ${$$.settings.breakpoint1}px)`).matches;
        $$.fn.debug('isDevices', () => {
          console.log(`(max-width: ${$$.settings.breakpoint1}px)`);
          console.log(`isSP:: ${flag}`);
        });
        return flag;
        break;

      default:
        $$.fn.debug('isDevices', () => {
          console.log(`isPC:: ${$$.fn.isDevices('PC')}`);
          console.log(`isTB:: ${$$.fn.isDevices('TB')}`);
          console.log(`isSP:: ${$$.fn.isDevices('SP')}`);
        });
        return;
    }
  };

  /**
   * メディアクエリに応じたイベントをトリガーする
   * @param {*} event
   */
  $$.fn.mqChecker = () => {
    if (window.matchMedia(`(min-width: ${$$.settings.breakpoint1 + 1}px)`).matches) {
      $$.fn.debug('mqChecker', () => {
        console.log('mql chenged:: PC');
      });
      window.dispatchEvent(new Event('isPC'));
    } else if (window.matchMedia(`(max-width: {$$.settings.breakpoint2}px)`).matches) {
      $$.fn.debug('mqChecker', () => {
        console.log('mql chenged:: SP');
      });
      window.dispatchEvent(new Event('isSP'));
    } else {
      $$.fn.debug('mqChecker', () => {
        console.log('mql chenged:: TB');
      });
      window.dispatchEvent(new Event('isTB'));
    }
  };

  // 基本設定 END ▲▲▲▲▲▲▲▲▲▲


  // 汎用関数 START ▼▼▼▼▼▼▼▼▼▼
  const anchorLink = () => {
    let allAnchor = document.querySelectorAll('a');

    [...allAnchor].map((el) => {
      el.addEventListener('click', (e) => {
        // console.log(e);
        let href = el.getAttribute('href');
        if (!href) return;

        let match = href.match(/^\#(.*?)$/);
        if (!match) return;

        let targetID = match[1];
        e.preventDefault();

        let $target = document.getElementById(targetID);
        if(!$target) return;
        let targetOffsetTop = $$.fn.offset($target, 'top');

        $$.fn.smoothScroll(targetOffsetTop, 1000);
      });
    });
  };
  // 汎用関数 END ▲▲▲▲▲▲▲▲▲▲

  // 共通部分 START ▼▼▼▼▼▼▼▼▼▼
  const layoutHeader = () => {
    const self = {
      $root: document.getElementById('LayoutHeader')
    };

    const initHeadroom = (() => {
      const headroom = new Headroom(self.$root);
      headroom.init();
    })();
  };

  const globalNavi = () => {
    const self = {
      $trigger: document.getElementById('DrawerTrigger'),
      $target: document.getElementById('GlobalNavi'),
      duration: 400,
    };

    const toggleOpen = () => {
      if (!self.$trigger.classList.contains('is-active')) {
        self.$trigger.classList.add('is-active');
        $$.fn.animate((e) => {
          self.$target.style.transform = `translateX(${100 - (100 * e.easing(e.progress))}%)`;
        }, self.duration);
        window.dispatchEvent(new Event('bodyFixedOn'));
      } else {
        self.$trigger.classList.remove('is-active');
        $$.fn.animate((e) => {
          self.$target.style.transform = `translateX(${100 * e.easing(e.progress)}%)`;
        }, self.duration, $$.ease.out.cubic, () => {
          self.$target.style = '';
          window.dispatchEvent(new Event('bodyFixedOff'));
        });
      }
    };

    const run = (() => {
      self.$trigger.addEventListener('click', () => {
        toggleOpen();
      });
    })();

  };

  const mainNavi = (base) => {
    const self = {};
    self.base = base || '.c-main-navi';
    self.$root = document.querySelector(self.base);
    self.$items = self.$root.querySelectorAll(`${self.base}__item`);

    const init = (() => {
      [...self.$items].map((item, i) => {
        const $children = item.querySelector(`${self.base}__children`);
        if ($children) item.classList.add('has-child');
      });
    })();

    const reset = () => {
      [...self.$items].map((item) => {
        item.classList.remove('is-active');
      });
    }

    const accordion = () => {
      [...self.$items].map((item, i) => {
        const $children = item.querySelector(`${self.base}__children`);
        const eventEscape = item.querySelectorAll(`${self.base}__children, a`);
        if (!eventEscape) return;

        [...eventEscape].map((el) => {
          el.addEventListener('click', (e) => {
            e.stopPropagation();
          });
        });

        item.addEventListener('click', (e) => {
          if ($$.fn.isDevices('PC') || !$children) return;
          if (item.classList.contains('is-active')) {
            item.classList.remove('is-active');
            $$.fn.slideUp($children);
          } else {
            item.classList.add('is-active');
            $$.fn.slideDown($children);
          }
        });
      })
    }

    const run = (() => {
      accordion();
    })();
  };

  const footerMainNavi = () => {
    const self = {};
    self.$root = document.querySelector('.c-fooer-main-navi');
    self.allTitle = document.querySelectorAll('.c-footer-main-navi__title');
    self.allChildren = document.querySelectorAll('.c-footer-main-navi__children');

    const init = () => {
      [...self.allTitle].map((title, i) => {
        let children = title.nextElementSibling;
        if (children) {
          title.classList.add('has-child');
        }
      });
    };

    const accordion = () => {
      [...self.allTitle].map((title, i) => {
        const $children = title.nextElementSibling;
        title.addEventListener('click', (e) => {
          if ($$.fn.isDevices('PC') || !title.classList.contains('has-child')) return;

          if (!title.classList.contains('is-active')) {
            title.classList.add('is-active');
            $$.fn.slideDown($children);
          } else {
            title.classList.remove('is-active');
            $$.fn.slideUp($children);
          }
        });
      });
    };

    const run = (() => {
      init();
      accordion();
    })();
  };

  // 共通部分 END ▲▲▲▲▲▲▲▲▲▲

  ['DOMContentLoaded', 'load', 'resize', 'scroll'].map((event) => {
    window.addEventListener(event, (e) => {
      if (event === 'DOMContentLoaded') {
        $$.fn.debug('window.CI', () => {
          console.log(window.CI);
        });
        anchorLink();
        layoutHeader();
        globalNavi();
        mainNavi();
        footerMainNavi();
      } else if (event === 'load') {
      } else if (event === 'resize') {
      } else if (event === 'scroll') {
      }
    });
  });
  window.addEventListener('bodyFixedOn', () => {
    $$.settings.tmpScrollTop = window.scrollY;
    $$.elem.body.style.position = 'fixed';
    $$.elem.body.style.top = `-${$$.settings.tmpScrollTop}px`;
  });
  window.addEventListener('bodyFixedOff', () => {
    $$.elem.body.style.position = '';
    $$.elem.body.style.top = ``;
    window.scrollTo(0, $$.settings.tmpScrollTop);
  });

  $$.settings.mql.addEventListener("change", () => {
    $$.fn.debug('settings.mql changed', () => {
    });
    $$.fn.mqChecker();
    window.dispatchEvent(new Event('mqlChange'));
  });

})(window.CI);
